import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: "index",
    component: () => import('../views/login.vue')
  },
  {
    path: '/',
    name: "web",
    component: () => import('../views/WebOfficialWebsite.vue')
  },
  {
    path: '/app',
    name: "app",
    component: () => import('../views/AppOfficialWebsite.vue')
  },
  {
    path: '/appUseApply',
    name: "appUseApply",
    component: () => import('../views/appUseApply.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  var info = navigator.userAgent;
  var isPhone = /mobile/i.test(info);
  console.log("此设备" + isPhone + "手机！！！");
  if (isPhone && to.name === "web") {
    next("/app")
  }
  if (!isPhone && to.name === 'app') {
    next("/")
  }
  if (isPhone && to.name === "index") {
    next("/appUseApply")
  }
  if (!isPhone && to.name === 'appUseApply') {
    next("/login")
  }
  next()
})

export default router
